<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Transportadoras" :class="{ tabs: empresaEdita.id }">
          <form-adicionar-empresa-relacao
            v-if="showForm || irParaCadastro"
            @adicionar="adicionarEmpresaRelacao"
            campoEmpresaOrigem="empresa"
            campoEmpresaDestino="transportadora"
          />
          <form-editar-empresa-relacao
            v-else-if="editarTransportadora"
            v-model="transportadoraSelecionada"
            @atualizar-contatos="salvarContatos"
            @atualiza-RNTRC="atualizaRNTRC"
            @close="editarTransportadora = false"
          />
          <listar-empresas-relacao
            v-else
            v-model="transportadoras"
            :tela="permission"
            title="Transportadoras registradas"
            titleNew="Nova Transportadora"
            campoId="transportadora_id"
            @update="atualizarListaTransportadoras"
            @delete="confirmaExcluirTransportadora"
            @showForm="showForm = true"
            @editar="editar"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListarEmpresasRelacao from '@/components/empresaRelacao/ListarEmpresasRelacao'
import FormAdicionarEmpresaRelacao from '@/components/empresaRelacao/adicionar/FormAdicionarEmpresaRelacao.vue'
import FormEditarEmpresaRelacao from '@/components/empresaRelacao/editar/FormEditarEmpresaRelacao.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  props: {
    irParaCadastro: {
      default: false,
    },
  },
  data() {
    return {
      Sequences,
      showForm: false,
      editarTransportadora: false,
      page: {
        title: 'Gerenciamento de transportadoras',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
        },
        {
          text: 'Transportadora',
          disabled: true,
        },
      ],
      transportadoraSelecionada: {},
      empresaEdita: {},
      loading: false,
    }
  },
  components: {
    ListarEmpresasRelacao,
    FormAdicionarEmpresaRelacao,
    FormEditarEmpresaRelacao,
  },
  computed: {
    ...mapState('transportadoras', ['transportadoras']),
    permission() {
      return Sequences.Transportadoras.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      this.getTransportadoras()
    }
  },
  methods: {
    ...mapActions('transportadoras', [
      'getTransportadoras',
      'adicionarTransportadora',
      'atualizarTransportadora',
      'excluirTransportadora',
      'atualizarContatos',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    load() {
      this.loading = true
    },
    loaded() {
      this.loading = false
    },
    async atualizarListaTransportadoras() {
      try {
        this.load()
        await this.getTransportadoras()
      } catch (error) {
        this.loaded()
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage('Erro ao atualizar lista de transportadoras:' + error)
      } finally {
        this.loaded()
      }
    },
    async confirmaExcluirTransportadora(transportadora) {
      try {
        this.excluirTransportadora(transportadora)
        this.successMessage('Empresa excluída com sucesso')
      } catch (error) {
        this.errorMessage('Erro ao excluir empresa')
      }
    },
    editar(transportadora) {
      this.transportadoraSelecionada = transportadora
      this.editarTransportadora = true
    },
    async adicionarEmpresaRelacao(empresa) {
      try {
        await this.adicionarTransportadora(empresa)
        this.successMessage('Empresa adicionada com sucesso.')
        this.getTransportadoras()
        this.showForm = false
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    async salvarContatos(contatos) {
      try {
        await this.atualizarContatos({
          transportadora: this.transportadoraSelecionada.public_id,
          contatos,
        })
        this.successMessage('Contato atualizado com sucesso')
        this.getTransportadoras()
      } catch (e) {
        this.errorMessage('Erro ao atualizar lista de contatos')
      }
    },
    async atualizaRNTRC(RNTRC) {
      try {
        this.load()
        const public_id = RNTRC.public_id
        delete RNTRC.public_id
        await this.atualizarTransportadora({
          public_id: public_id,
          transportadora: { ...RNTRC },
        })
        this.successMessage('Transportadora editada!')
      } catch (error) {
        this.loaded()
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.loaded()
      }
    },
  },
}
</script>

<style></style>
